//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'login',
  auth: 'guest',
  data: () => ({
    invalidCreds: false,
    email: '',
    password: '',
  }),
  methods: {
    async login() {
      try {
        await this.$auth.loginWith('local', {
          data: {
            username: this.email,
            password: this.password,
          },
        })

        if (this.$auth.user?.requirePasswordReset) {
          this.$router.push('reset-password')
        } else {
          this.$router.push('/')
        }
      } catch (err) {
        if (err.response?.status === 401) {
          this.invalidCreds = true
        } else {
          this.$sentry.captureException(err)
        }
      }
    },

    loginWithMicrosoft() {
      window.location.replace(this.$config.msLoginUrl)
    },
  },
}
